import { Nav } from './nav';

export const HeaderNavList: Nav[] = [
  {
    id: 1,
    label: '¿Qué es DRIVE?',
    url: '#services-section',
    icon: null,
    class: null,
    active: false,
    target: null
  },
  {
    id: 2,
    label: '¿Cómo funciona?',
    url: '#how-work-section',
    icon: null,
    class: null,
    active: false,
    target: null
  },
  {
    id: 3,
    label: 'Beneficios',
    url: '#benefits-section',
    icon: null,
    class: null,
    active: false,
    target: null
  },
  {
    id: 4,
    label: 'Estacionamientos',
    url: '#when-using-section',
    icon: null,
    class: null,
    active: false,
    target: null
  },
  {
    id: 5,
    label: 'Contacto',
    url: '#contact-section',
    icon: null,
    class: null,
    active: false,
    target: null
  },
  {
    id: 6,
    label: 'Facturación',
    url: 'https://app.driveapp.mx/facturacion/',
    icon: null,
    class: null,
    active: false,
    target: '_blank'
  }
];