import { Featured } from './featured';

export const FeaturedsAvailablesList: Featured[] = [
  {
    id: 1,
    label: 'Estacionamientos',
    icon: '/assets/parking@3x.png',
  },
  {
    id: 2,
    label: 'Parquímetros',
    icon: '/assets/parkingmeter@3x.png',
  },
  {
    id: 3,
    label: 'Facturación automática',
    icon: '/assets/invoice@3x.png',
  }
];

export const FeaturedsComingList: Featured[] = [
    {
      id: 1,
      label: 'Trámites de gobierno',
      icon: '/assets/govern@3x.png',
    },
    {
      id: 2,
      label: 'Gasolina',
      icon: '/assets/gas station@3x.png',
    },
    {
      id: 3,
      label: 'Seguros',
      icon: '/assets/insurance@3x.png',
    },
    {
      id: 4,
      label: 'Trámites de gobierno',
      icon: '/assets/govern@3x.png',
    },
    {
      id: 5,
      label: 'Gasolina',
      icon: '/assets/gas station@3x.png',
    },
    {
      id: 6,
      label: 'Seguros',
      icon: '/assets/insurance@3x.png',
    },
    {
      id: 7,
      label: 'Trámites de gobierno',
      icon: '/assets/govern@3x.png',
    },
    {
      id: 8,
      label: 'Gasolina',
      icon: '/assets/gas station@3x.png',
    },
    {
      id: 9,
      label: 'Seguros',
      icon: '/assets/insurance@3x.png',
    }
  ];

  export const FeaturedsBenefitsList: Featured[] = [
    {
      id: 1,
      label: 'Reducción de uso de efectivo.',
      icon: '/assets/credit_card@3x.png',
    },
    {
      id: 2,
      label: 'Seguridad y simplicidad.',
      icon: '/assets/security@3x.png',
    },
    {
      id: 3,
      label: 'Monitoreo de gastos automatizados.',
      icon: '/assets/statistic@3x.png',
    }
  ];