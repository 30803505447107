import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.css']
})
export class ContactSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(function(){

      $("#botonEnviar").click(function() {
        var label_current = $(this).html();
        $(this).html('<i class="fas fa-circle-notch fa-spin"></i> Enviando...');
        event.preventDefault();
        var regex = /[\w-\.]{2,}@([\w-]{2,}\.)*([\w-]{2,}\.)[\w-]{2,4}/;
        var fields_alert = [];
        if($("#input_nombre").val().trim() == ""){
          fields_alert.push("Nombre");
          $('#input_nombre').focus();
        };
        if($("#input_email").val().trim() == ""){
          fields_alert.push("Email");
          $('#input_email').focus();
        }else{
          if (!regex.test($('#input_email').val().trim())) {
            $('#alert-msg').html('El <b>Email</b> no es valido');
            $('#alert-msg').slideDown(380);
            $('#alert-msg').delay(5000).slideUp(250);
            $('#botonEnviar').html(label_current);
            $("#input_email").focus();
            return false;
          }
        };
        if($("#contacto_mensaje").val().trim() == ""){
          fields_alert.push("Mensaje");
          $('#contacto_mensaje').focus();
        };

        var mensaje = 'Por favor, Ingrese su <b>'+fields_alert.join(", ")+'</b> antes de continuar';
        if(fields_alert.length > 0){
          $('#alert-msg').html(mensaje);
          $('#alert-msg').slideDown(380);
          $('#botonEnviar').html(label_current);
          $('#alert-msg').delay(5000).slideUp(250);
          return false;
        }
        
        var dataString = $('#contacto').serialize();
        $.post("/assets/send.php", dataString).done(function(data) {
          $('#alert-msg').html(data);
          $('#alert-msg').slideDown(380);
          $('#botonEnviar').html("Enviado").delay(3500).html(label_current);
          $('#alert-msg').delay(5000).slideUp(250);
          $('#contacto')[0].reset();
        }).fail(function(){
          $('#alert-msg').html("Algo salío mal :(, Por favor, intente de nuevo más tarde...");
          $('#alert-msg').slideDown(380);
          $('#botonEnviar').html("Enviado").delay(3500).html(label_current);
          $('#alert-msg').delay(5000).slideUp(250);
        });
      });

    });
  }

}
