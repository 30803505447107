import { SocialMedia } from './social-media';

export const SocialMediaList: SocialMedia[] = [
  {
    icon: '<i class="fab fa-facebook-square"></i>',
    link: 'https://www.facebook.com/DriveAppMx/',
    label: 'Síguenos en Facebook'
  },
  {
    icon: '<i class="fab fa-instagram"></i>',
    link: 'https://www.instagram.com/driveapp.mx/',
    label: 'Síguenos en Instagram'
  },
  {
    icon: '<i class="fab fa-linkedin"></i>',
    link: 'https://www.linkedin.com/company/driveapp/',
    label: 'Síguenos en LinkedIn'
  },
  {
    icon: '<i class="fab fa-twitter-square"></i>',
    link: 'https://twitter.com/DriveAppMx',
    label: 'Síguenos en Twitter'
  }
];