import { Video } from './video';

export const HowWorkVideoListEs: Video[] = [
  {
    id: 1,
    video: {
        poster: '/assets/videos/que-es-drive.png',
        src: '/assets/videos/que-es-drive.mp4',
        type: 'video/mp4'
    },
    title: '¿Qué es DRIVE?',
  },
  {
    id: 2,
    video: {
        poster: null,
        src: '/assets/videos/como-funciona.mp4',
        type: null
    },
    title: 'Experiencia de pago con estacionamiento con DRIVE',
  },
  {
    id: 3,
    video: {
        poster: '/assets/videos/4-pasos.png',
        src: '/assets/videos/4-pasos.mp4',
        type: null
    },
    title: '4 Pasos para pagar con DRIVE',
  }
];



