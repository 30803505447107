import { Component, OnInit } from '@angular/core';
import { WhenUsingIcons } from '../icon-blocks-list';
declare var $: any;

@Component({
  selector: 'app-when-using-section',
  templateUrl: './when-using-section.component.html',
  styleUrls: ['./when-using-section.component.css']
})
export class WhenUsingSectionComponent implements OnInit {

  when_using_icons = WhenUsingIcons;

  constructor() { }

  ngOnInit() {
    $(document).ready(function(){
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
        if ($(window).width() < 768){	
          $('.when-using-featureds').owlCarousel({
            items: 5,
            loop:true,
            margin:10,
            autoplay:true,
            autoplayTimeout:8000,
            autoplayHoverPause:true,
            dots:true,
            pagination: true,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                },
                600:{
                    items:2,
                },
            }
          });
        }
        
      }
    });
  }

}
