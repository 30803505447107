import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../translate.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { WOW } from 'wowjs/dist/wow.min';
declare var $: any;
declare var jQuery: any;

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.css'],
	providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class MainComponent implements OnInit {

	lang: string;

	constructor(private translate: TranslateService, private location: Location, private route: ActivatedRoute, private router: Router) {
		this.lang = this.route.params['value'].lang == undefined || this.route.params['value'].lang.length > 2 || this.route.params['value'].lang == "" ? this.translate.lang : this.route.params['value'].lang;

		// Change to lang path example: localhost:4200/en for lang english
		this.setLang(this.lang);

		this.router.routeReuseStrategy.shouldReuseRoute = function () {
			return false;
		}
		this.router.events.subscribe((evt) => {
			if (evt instanceof NavigationEnd) {
				// trick the Router into believing it's last link wasn't previously loaded
				this.router.navigated = false;
				// if you need to scroll back to top, here is the right place
				window.scrollTo(0, 0);
			}
		});
	}

	setLang(lang: string) {
		this.translate.use(lang);
	}

	ngOnInit() {
		var lang = this.lang;
		// console.log(lang);

		$('.parallax-window').parallax();

		var wow = new WOW({
			live: false
		});
		wow.init();

		$(function () {

			$('base').attr('href', './' + (lang != 'es' ? lang : ''));

			// Get data privacy
			$.post("https://app.driveapp.mx/drive/ws2/cuestionsapp.php", { idioma: lang })
				.done(function (data) {
					var jwt = jQuery.parseJSON(data);

					var privacy_data = jwt.questions.find(question => question.pk === '7');
					var privacy = {
						id: privacy_data.pk,
						label: privacy_data.question,
						tag: privacy_data.tag,
						html: "<h1><span class='title-highlight'>" + privacy_data.question + "</span></h1><p></p>",
					};

					var terms_data = jwt.questions.find(question => question.pk === '8');
					var terms = {
						id: terms_data.pk,
						label: terms_data.question,
						tag: terms_data.tag,
						html: "<h1><span class='title-highlight'>" + terms_data.question + "</span></h1><p></p>",
					}

					var faqs = [
						{
							id: jwt.questions[0].pk,
							label: jwt.questions[0].question,
							tag: jwt.questions[0].tag,
							html: "<h3><b>" + jwt.questions[0].question + "</b></h3><p></p>",
						},
						{
							id: jwt.questions[1].pk,
							label: jwt.questions[1].question,
							tag: jwt.questions[1].tag,
							html: "<h3><b>" + jwt.questions[1].question + "</b></h3><p></p>",
						},
						{
							id: jwt.questions[2].pk,
							label: jwt.questions[2].question,
							tag: jwt.questions[2].tag,
							html: "<h3><b>" + jwt.questions[2].question + "</b></h3><p></p>",
						},
						{
							id: jwt.questions[3].pk,
							label: jwt.questions[3].question,
							tag: jwt.questions[3].tag,
							html: "<h3><b>" + jwt.questions[3].question + "</b></h3><p></p>",
						},
						{
							id: jwt.questions[4].pk,
							label: jwt.questions[4].question,
							tag: jwt.questions[4].tag,
							html: "<h3><b>" + jwt.questions[4].question + "</b></h3><p></p>",
						},
					]
					$.each(jwt.answers, function (index, answer) {
						if (answer.fk == privacy.id) {
							privacy.html = privacy.html + answer.body;
						}
						if (answer.fk == terms.id) {
							terms.html = terms.html + answer.body;
						}
						$.each(faqs, function (index, faq) {
							if (answer.fk == faq.id) {
								faq.html = faq.html + "<p><h6 class='drive-main-color'>" + answer.header + "</h6>" + answer.body + "</p>";
							}
						});
					});

					var faqs_concat = "<h1><span class='title-highlight'>" + $('a#faqs').text() + "</span></h1><p></p>";
					$.each(faqs, function (index, faq) {
						faqs_concat = faqs_concat + "<p></p><p></p>" + faq.html;
					});
					// Creamos el objeto de privacy
					var privacy_div = document.createElement('div');
					privacy_div.id = privacy.tag;
					privacy_div.innerHTML = privacy.html;
					privacy_div.style.display = 'none';
					privacy_div.className = 'content-box';
					$('body').prepend(privacy_div);

					// Creamos el objeto de terms
					var terms_div = document.createElement('div');
					terms_div.id = terms.tag;
					terms_div.innerHTML = terms.html;
					terms_div.style.display = 'none';
					terms_div.className = 'content-box';
					$('body').prepend(terms_div);

					// Creamos el objeto de terms
					var faqs_div = document.createElement('div');
					faqs_div.id = "faqs";
					faqs_div.innerHTML = faqs_concat;
					faqs_div.style.display = 'none';
					faqs_div.className = 'content-box';
					$('body').prepend(faqs_div);
					// console.log(faqs);
				});
			// End Get data privacy

			// Smooth Scroll
			$('a[href*="#"]:not([href="#"])').click(function () {
				if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
					var target = $(this.hash);
					target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
					if (target.length) {
						$('html, body').animate({
							scrollTop: target.offset().top
						}, 1000);
						return false;
					}
				}
			});
			// End Smooth Scroll

			// Nav collapse
			$('.navbar-nav>li>a').on('click', function () {
				$('.navbar-collapse').collapse('hide');
			});

			/** Nav Fixed scroll */
			var navbar = $('header#header'),
				scrollClass = 'bg-fixed',
				activateAtY = 81;

			function fixedNav() {
				if (!navbar.hasClass(scrollClass)) {
					navbar.addClass(scrollClass);
					$('.social-media-float').addClass(scrollClass);
				}
			}

			function resetNav() {
				if (navbar.hasClass(scrollClass) && !$('div.navbar-collapse').hasClass('show')) {
					navbar.removeClass(scrollClass);
					$('.social-media-float').removeClass(scrollClass);
				}
			}

			$(window).scroll(function () {
				if ($(window).scrollTop() > activateAtY) {
					fixedNav();
				}
				if ($(window).scrollTop() == 0) {
					resetNav();
				}
			});

			$('button.navbar-toggler').click(function () {
				if (!navbar.hasClass('bg-fixed')) {
					navbar.toggleClass(scrollClass);
				}
			});
			/** Nav Fixed scroll end */

			/** Nav syncro hover */
			$("#navbar ul.navbar-nav li").on({
				mouseenter: function () {
					var link_hash = $(this).find('a').attr('href');
					$('nav.timeline li a[href="' + link_hash + '"]').addClass('hover');
				},
				mouseleave: function () {
					var link_hash = $(this).find('a').attr('href');
					$('nav.timeline li a[href="' + link_hash + '"]').removeClass('hover');
				}
			});

			$("nav.timeline li").on({
				mouseenter: function () {
					var link_hash = $(this).find('a').attr('href');
					$('#navbar ul.navbar-nav li > a[href="' + link_hash + '"]').addClass('hover');
				},
				mouseleave: function () {
					var link_hash = $(this).find('a').attr('href');
					$('#navbar ul.navbar-nav li > a[href="' + link_hash + '"]').removeClass('hover');
				}
			});
			/** End Nav syncro hover */

		});

	}

}
