import { Component, OnInit } from '@angular/core';
import { TranslateService } from './translate.service';
import { Meta } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  title = 'DRIVE';
  url_home: string = window.location.href;

  constructor(private meta: Meta, private translate: TranslateService){

    // Tags SEO
    this.meta.addTag({ name: 'keywords', content: this.translate.data['seo.keywords']});
    this.meta.addTag({ name: 'description', content: this.translate.data['seo.description']});

    // Social Media: Twitter
    this.meta.addTags([
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@driveappmx' },
      { name: 'twitter:title', content: this.translate.data['seo.title'] },
      { name: 'twitter:description', content: this.translate.data['seo.description'] },
      { name: 'twitter:image', content: this.url_home+'assets/'+this.translate.data['seo.image.1'] }
    ]);

    // Social Media: Facebook
    this.meta.addTags([
      { name: 'og:title', content: this.translate.data['seo.title'] },
      { name: 'og:site_name', content: 'Landing Page Drive App' },
      { name: 'og:url', content: 'driveapp.mx' },
      { name: 'og:description', content: this.translate.data['seo.description'] },
      { name: 'og:type', content: 'website' },
      { name: 'og:image', content: this.url_home+'assets/'+this.translate.data['seo.image.1'] },
      { name: 'og:image', content: this.url_home+'assets/'+this.translate.data['seo.image.2'] },
      { name: 'og:image', content: this.url_home+'assets/'+this.translate.data['seo.image.3'] }
    ]);
  }


  ngOnInit(){

    console.log("Init App");

    $('.parallax-window').parallax();

    $(document).ready(function(){

      // Smooth Scroll
      $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
      // End Smooth Scroll

      // Lightbox
      // Close Lightbox
      $('.lightbox').click(function(){
        event.preventDefault();
        if($(event.target).parent().hasClass('lightbox')){
          $('.lightbox .content').animate({
            opacity: 0,
            top: '-=250'
          }, 500, function(){
            $('.lightbox').find('.content').html('');
            $('.lightbox').fadeOut('fast', function(){
              $('.lightbox').find('.content').html('<i class="fas fa-circle-notch fa-spin fa-3x"></i>');
            });
          });

        }
        event.stopPropagation();
      });
      // Load Content lightbox
      $('[data-lightbox]').click(function(){
        event.preventDefault();
        var target = $(this).data('lightbox-target');
        var type = $(this).data('lightbox-type');
        $('.lightbox .content').css({
          'opacity': 0,
          'top': '-500px'
        });

        var object = null
        switch (type) {
          case 'video':
            object = document.createElement('video');
            object.controls = true;
            object.src = target;
            object.autoplay = true;
            break;

          case 'image':
            object = document.createElement('img');
            object.src = target;
            break;

          case 'pdf':
            object = document.createElement('object');
            object.width = "100%";
            object.height = "100%";
            object.type = "application/pdf";
            object.data = target;
            // object.pluginspage = "http://www.adobe.com/products/acrobat/readstep2.html";
            break;

          case 'div':
            var div = document.getElementById(target);
            object = div.cloneNode(true);
            object.style.display = "block";
            break;

          default:
            break;
        }

        // Load object in content
        $('.lightbox').find('.content').html(object);
        $('.lightbox').fadeIn('fast');
        $('.lightbox .content').animate({
          opacity: 1,
          top: 0
        },450);

      });
      // End Lightbox

      // Custom Play Button video
      // $('video').each(function(){
      //   var this_ = $(this);
      //   this_.on('playing', function(){
      //     this_.attr('controls',true);
      //     console.log("playing");
      //   });
      //   this_.on('pause', function(){
      //     this_.attr('controls',false);
      //     this_.parents('.video-box').removeClass('active');
      //     console.log("paused");
      //   });
      // });
      // $('.video-content').click(function(){
      //   console.log("clic video");
      //   var video = $(event.target);
      //   if(video.parents('.video-box').hasClass('active')){
      //     video.get(0).pause();
      //     video.parents('.video-box').removeClass('active');
      //   }else{
      //     $('video').each(function(){
      //       $(this).get(0).pause();
      //     });
      //     video.parents('.video-box').addClass('active');
      //     video.get(0).play();
      //   }
      // })
      // End Custom Play Button video

    });// End $(document).ready()
  }
}
