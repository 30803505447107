import { Component, OnInit } from '@angular/core';
import { HeaderNavList } from '../header-nav-list';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '../translate.service';
declare var $: any;

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.css']
})
export class HeaderNavComponent implements OnInit  {

  headerNavList = HeaderNavList;
  navListCount = this.headerNavList.length;
  marginTop = 230/5;

  lang:string;

  constructor(private translate: TranslateService, private route: ActivatedRoute) {
    this.lang = this.route.params['value'].lang == undefined || this.route.params['value'].lang.length > 2 || this.route.params['value'].lang == "" ? this.translate.lang : this.route.params['value'].lang;
  }

  ngOnInit() {}

}
