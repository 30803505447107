import { Video } from './video';

export const HowWorkVideoListEn: Video[] = [
  {
    id: 1,
    video: {
      poster: '/assets/videos/que-es-drive-en.png',
      src: '/assets/videos/que-es-drive-en.mp4',
      type: 'video/mp4'
    },
    title: '¿Qué es DRIVE?',
  },
  {
    id: 2,
    video: {
      poster: null,
      src: '/assets/videos/como-funciona-en.mp4',
      type: null
    },
    title: 'Experiencia de pago con estacionamiento con DRIVE',
  },
  {
    id: 3,
    video: {
      poster: '/assets/videos/4-pasos-en.png',
      src: '/assets/videos/4-pasos-en.mp4',
      type: null
    },
    title: '4 Pasos para pagar con DRIVE',
  }
];



