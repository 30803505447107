import { Component, OnInit } from '@angular/core';
import { FeaturedsAvailablesList, FeaturedsComingList } from '../featureds-list';
declare var $: any;

@Component({
  selector: 'app-services-section',
  templateUrl: './services-section.component.html',
  styleUrls: ['./services-section.component.css']
})
export class ServicesSectionComponent implements OnInit {

  featuredsAvailablesList = FeaturedsAvailablesList;
  featuredsComingList = FeaturedsComingList;

  itemsPerRow = 3;
  rowsFeaturedsComing = Array.from(
    Array(Math.ceil(this.featuredsComingList.length/this.itemsPerRow)).keys()
  )

  constructor() { }

  ngOnInit() {
    var count_featureds_coming = this.featuredsComingList.length;
    var count_featureds_available = this.featuredsAvailablesList.length;
    $(document).ready(function(){
      if(count_featureds_coming > 3){
        $('#services-section #featureds-coming').owlCarousel({
          items:1,
          loop:true,
          margin:35,
          autoplay:true,
          autoplayTimeout:8000,
          autoplayHoverPause:true,
          dots:true,
          pagination: true,
        });
      }
      if(count_featureds_available > 3){
        $('#services-section #featureds-available').owlCarousel({
          items:1,
          loop:true,
          margin:35,
          autoplay:true,
          autoplayTimeout:8000,
          autoplayHoverPause:true,
          dots:true,
          pagination: true,
        });
      }
      
    });
  }

}
