import { Component, OnInit } from '@angular/core';
import { downloadIos, downloadAndroid, downloadAppgallery } from '../download-links';
import { SocialMediaList } from '../social-media-list';

@Component({
  selector: 'app-intro-section',
  templateUrl: './intro-section.component.html',
  styleUrls: ['./intro-section.component.css']
})
export class IntroSectionComponent implements OnInit {

  download_ios = downloadIos;
  download_android = downloadAndroid;
  download_appgallery = downloadAppgallery;
  social_Media_list = SocialMediaList;

  constructor() { }

  ngOnInit() {
  }

}
