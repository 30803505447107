import { Component, OnInit } from '@angular/core';
import { HowWorkVideoListEs } from '../how-work-video-list-es';
import { HowWorkVideoListEn } from '../how-work-video-list-en';
import { HowWorksIcons } from '../icon-blocks-list';
import { HelpersService } from '../helpers.service';
import { downloadIos, downloadAndroid, downloadAppgallery } from '../download-links';
import { ActivatedRoute} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-how-works-section',
  templateUrl: './how-works-section.component.html',
  styleUrls: ['./how-works-section.component.css']
})
export class HowWorksSectionComponent implements OnInit {

  // Download links
  download_ios = downloadIos;
  download_android = downloadAndroid;
  download_appgallery = downloadAppgallery;

  how_works_video_list:any;
  how_works_icons = HowWorksIcons;
  helpers = new HelpersService;

  constructor(private route: ActivatedRoute) {
    this.how_works_video_list = ( this.route.params['value'].lang === undefined) ? HowWorkVideoListEs : HowWorkVideoListEn;
    console.log(1);
  }

  ngOnInit() {
    console.log(2);
    var count_videos = this.how_works_video_list.length;
    var max_videos_for_page = 3;
    $(document).ready(function(){
      $('#how-work-section .content-videos').owlCarousel({
        items: (count_videos > max_videos_for_page ? max_videos_for_page : count_videos),
        loop:true,
        margin:10,
        autoplay:true,
        autoplayTimeout:8000,
        autoplayHoverPause:true,
        dots:true,
        pagination: true,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
            },
            600:{
                items:2,
            },
            1000:{
                items:max_videos_for_page,
                loop:false
            }
        },
        responsiveRefreshRate: 100,
        onTranslate: function() {
          $('#how-work-section .content-videos .owl-item').find('video').each(function() {
              this.pause();
          });
        }
      });

      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
        $('.how-works-featureds').owlCarousel({
          items: 5,
          loop:true,
          margin:10,
          autoplay:true,
          autoplayTimeout:8000,
          autoplayHoverPause:true,
          dots:true,
          pagination: true,
          responsiveClass:true,
          responsive:{
              0:{
                  items:1,
              },
              600:{
                  items:2,
              },
              1000:{
                  items:3,
              }
          }
        });
      }

    });
  }

}
