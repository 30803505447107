import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER  } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { IntroSectionComponent } from './intro-section/intro-section.component';
import { ServicesSectionComponent } from './services-section/services-section.component';
import { HowWorksSectionComponent } from './how-works-section/how-works-section.component';
import { BenefitsSectionComponent } from './benefits-section/benefits-section.component';
import { WhenUsingSectionComponent } from './when-using-section/when-using-section.component';
import { ContactSectionComponent } from './contact-section/contact-section.component';
import { FooterComponent } from './footer/footer.component';

import {TranslateModule} from "@ngx-translate/core";
import { HttpClientModule } from '@angular/common/http';
import { TranslateService } from './translate.service';
import { TranslatePipe } from './translate.pipe';
import { AppRoutingModule } from './app-routing.module';
import { MainComponent } from './main/main.component';

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderNavComponent,
    IntroSectionComponent,
    ServicesSectionComponent,
    HowWorksSectionComponent,
    BenefitsSectionComponent,
    WhenUsingSectionComponent,
    ContactSectionComponent,
    FooterComponent,
    TranslatePipe,
    MainComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot()
  ],
  providers: [
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ TranslateService ],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
