import { IconBlock } from './icon-block';

export const HowWorksIcons: IconBlock[] = [
  {
    id: 1,
    position: null,
    icon: 'promos@3x.png',
    content: 'Obtén promociones exclusivas y recibe kilómetros que podrás utilizar en futuras transacciones.',
    class: null
  },
  {
    id: 2,
    position: null,
    icon: 'credit_card@3x.png',
    content: 'Registra una o más tarjetas y administra de forma sencilla tus gastos.',
    class: null
  },
  {
    id: 3,
    position: null,
    icon: '$@3x.png',
    content: 'Olvídate del efectivo y mejora tu experiencia de pago.',
    class: null
  },
  {
    id: 4,
    position: null,
    icon: 'finger@3x.png',
    content: 'Realiza pagos con un click.',
    class: null
  },
  {
    id: 5,
    position: null,
    icon: 'star@3x.png',
    content: 'Da retroalimentación a los comercios y haz que tu opinion cuente.',
    class: null
  }
];

export const WhenUsingIcons: IconBlock[] = [
    {
      id: 1,
      position: 'right',
      icon: 'promos_2@3x.png',
      content: 'Tienes acceso a promociones exclusivas.',
      class: 'p-lg-5'
    },
    {
      id: 2,
      position: 'left',
      icon: 'v@3x.png',
      content: 'Por cada transacción DRIVE te cobrará $1, más $0.1 por cada $1 pagado de tu estacionamiento.',
      class: 'p-lg-5'
    },
    {
      id: 3,
      position: 'right',
      icon: 'cash@3x.png',
      content: 'Te olvidas de usar efectivo y cambiar tus billetes.',
      class: null
    },
    {
      id: 4,
      position: 'left',
      icon: 'expenses@3x.png',
      content: 'Podrás monitorear tus gastos.',
      class: null
    },
    {
      id: 5,
      position: 'center',
      icon: 'people@3x.png',
      content: 'Dejarás de hacer filas para pagar.',
      class: null
    }
  ];